<template>
  <div class="pap-popup">
    <template v-if="isVegaPopupEnable">
      <div class="pap-popup-inputs">
        <div class="pap-popup-inputs--text">
          <input type="text" v-model="vegaPopupName" class="pap-popup-inputs--text-input" :placeholder="$t('vega.popup.name')" v-validate="'required'" />
        </div>
        <div class="language-selection">
          <div class="language-selection-text">
            {{ $t('vue.selectLanguageText') }}
          </div>
          <div>
            <v-select
              name="language-select"
              class="language-selection-select mb-4 md:mb-0 w-full"
              style="display: inline-block; max-width: 520px"
              :options="webconnectSupportedLanguages"
              :placeholder="$t('vue.selectLanguage')"
              label="lnFullText"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="selectedLanguage"
              v-validate="'required'"
              validate-on="blur"
            >
              <template v-slot:option="option">
                <div>
                  {{ option.lnFullText }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="flex items-center">
          <span @click="toggleActivateOnlyB2B">
            <toggle-switch-icon :enabled="isActivateOnlyB2B" :width="40" :height="40" />
          </span>

          <div class="ml-2">{{ $t('vega.popup.activateForB2BOnly') }}</div>
        </div>

        <div class="flex items-center">
          <span @click="toggleEnableVegaPopup">
            <toggle-switch-icon :enabled="isVegaPopupEnable" :width="40" :height="40" />
          </span>

          <div class="ml-2">Pop-Up</div>
        </div>

        <div class="flex items-center">
          <span @click="toggleShowVegaOnlyWhenAgentsAreOnline">
            <toggle-switch-icon :enabled="showVegaOnlyWhenAgentsOnline" :width="40" :height="40" />
          </span>

          <div class="ml-2">{{ $t('vega.popup.showVegaOnlyWehnAgentsOnline') }}</div>
        </div>

        <div v-if="activeUserInfo && company" class="mt-5 flex flex-wrap items-center mb-base">
          <div class="flex items-center mb-5">
            <span class="mr-2 pa-vega-measurement__title-inner">{{ $t('views.vegaMeasurement.targetGroup') }}</span>

            <vx-tooltip class="flex items-center" style="width: 17px" position="top" :title="$t('views.vegaMeasurement.infoWidget')" :text="''">
              <img width="17" height="17" :src="infoImg" style="display: inline-block" />
            </vx-tooltip>
          </div>

          <vs-row v-for="(item, index) in scoreList" :key="index">
            <div class="vx-row mb-3">
              <div class="vx-col w-full">
                <vs-checkbox hide-details class="ma-0 pt-0 pb-1 checkbox-main" v-model="item.isSelected" @change="scoreUpdated(item)">
                  {{ item.text }}
                </vs-checkbox>
              </div>
            </div>
          </vs-row>
        </div>

        <div class="pap-popup-inputs--label">{{ $t('vega.popup.addPopup') }}</div>
        <div class="pap-popup-inputs--text">
          <div v-if="!canUpdateTextFields" class="w-full">English</div>
          <input type="text" v-model="popupText" class="pap-popup-inputs--text-input" :placeholder="$t('vega.popup.headline')" />
          <div v-if="!canUpdateTextFields" class="w-full mt-2">{{ this.selectedLanguageForVegaPopup.lnFullText }}</div>
          <input
            v-if="!canUpdateTextFields"
            type="text"
            v-model="tWebConnectVegaText"
            class="pap-popup-inputs--text-input"
            :placeholder="$t('vega.popup.headline')"
          />
        </div>
        <div class="pap-popup-inputs--textarea">
          <div v-if="!canUpdateTextFields" class="w-full">English</div>
          <quill-editor v-model="popupMessage" ref="quillEditorA" :options="editorOption" @text-change="onTextChange"> </quill-editor>
          <div v-if="!canUpdateTextFields" class="w-full mt-2">{{ this.selectedLanguageForVegaPopup.lnFullText }}</div>
          <quill-editor
            v-if="!canUpdateTextFields"
            v-model="tWebConnectVegaMessage"
            ref="quillEditorATranslation"
            :options="editorOption"
            @text-change="onTextChangeTranslation"
          >
          </quill-editor>
        </div>

        <div class="pap-popup-inputs--label mt-4">{{ $t('vega.popup.popUpStyle') }}</div>
        <div class="flex items-center">
          <span @click="isVegaPopupWithoutImage = !isVegaPopupWithoutImage">
            <toggle-switch-icon :enabled="isVegaPopupWithoutImage" :width="40" :height="40" />
          </span>

          <div class="ml-2">{{ $t('vega.popup.withoutImage') }}</div>
        </div>

        <div class="pap-popup-inputs--label mt-4">{{ $t('vega.popup.popupPosition') }}</div>
        <div class="flex">
          <div class="pap-popup-radio-wrapper">
            <vs-radio v-model="popupPosition" :vs-value="VEGA_POPUP_POSITION.CENTERED.value" class="mt-2 mr-4 pap-popup-radio" vs-name="widget-position">
              {{ VEGA_POPUP_POSITION.CENTERED.text }}
            </vs-radio>
            <vs-radio
              v-model="popupPosition"
              :vs-value="VEGA_POPUP_POSITION.VISITOR_MOUSE_POSITION.value"
              class="mt-2 mr-4 pap-popup-radio"
              vs-name="widget-position"
            >
              {{ VEGA_POPUP_POSITION.VISITOR_MOUSE_POSITION.text }}
            </vs-radio>
          </div>

          <div class="pap-popup-radio-wrapper">
            <vs-radio v-model="popupPosition" :vs-value="VEGA_POPUP_POSITION.CENTER_TOP.value" class="mt-2 mr-4 pap-popup-radio" vs-name="widget-position">
              {{ VEGA_POPUP_POSITION.CENTER_TOP.text }}
            </vs-radio>
            <vs-radio v-model="popupPosition" :vs-value="VEGA_POPUP_POSITION.CENTER_BOTTOM.value" class="mt-2 mr-4 pap-popup-radio" vs-name="widget-position">
              {{ VEGA_POPUP_POSITION.CENTER_BOTTOM.text }}
            </vs-radio>
          </div>

          <div class="pap-popup-radio-wrapper">
            <vs-radio v-model="popupPosition" :vs-value="VEGA_POPUP_POSITION.LEFT_TOP.value" class="mt-2 mr-4 pap-popup-radio" vs-name="widget-position">
              {{ VEGA_POPUP_POSITION.LEFT_TOP.text }}
            </vs-radio>
            <vs-radio v-model="popupPosition" :vs-value="VEGA_POPUP_POSITION.LEFT_CENTER.value" class="mt-2 mr-4 pap-popup-radio" vs-name="widget-position">
              {{ VEGA_POPUP_POSITION.LEFT_CENTER.text }}
            </vs-radio>
            <vs-radio v-model="popupPosition" :vs-value="VEGA_POPUP_POSITION.LEFT_BOTTOM.value" class="mt-2 mr-4 pap-popup-radio" vs-name="widget-position">
              {{ VEGA_POPUP_POSITION.LEFT_BOTTOM.text }}
            </vs-radio>
          </div>

          <div class="pap-popup-radio-wrapper">
            <vs-radio v-model="popupPosition" :vs-value="VEGA_POPUP_POSITION.RIGHT_TOP.value" class="mt-2 mr-4 pap-popup-radio" vs-name="widget-position">
              {{ VEGA_POPUP_POSITION.RIGHT_TOP.text }}
            </vs-radio>
            <vs-radio v-model="popupPosition" :vs-value="VEGA_POPUP_POSITION.RIGHT_CENTER.value" class="mt-2 mr-4 pap-popup-radio" vs-name="widget-position">
              {{ VEGA_POPUP_POSITION.RIGHT_CENTER.text }}
            </vs-radio>
            <vs-radio v-model="popupPosition" :vs-value="VEGA_POPUP_POSITION.RIGHT_BOTTOM.value" class="mt-2 mr-4 pap-popup-radio" vs-name="widget-position">
              {{ VEGA_POPUP_POSITION.RIGHT_BOTTOM.text }}
            </vs-radio>
          </div>
        </div>

        <div class="pap-popup-inputs--label mt-4">{{ $t('vega.popup.vegaLockingTime') }}</div>
        <input type="number" v-model="vegaLockingTime" class="pap-popup-inputs--text-input" />
      </div>
      <div class="pap-popup-preview">
        <div class="pap-popup-inputs--label">{{ $t('vega.choose.previewLabel') }}</div>
        <VegaPreview :text="popupText" :message="popupMessage" />
      </div>
    </template>

    <template v-if="!isVegaPopupEnable">
      <div class="pap-popup__preview-settings">
        <div class="flex items-center">
          <span @click="toggleActivateOnlyB2B">
            <toggle-switch-icon :enabled="isActivateOnlyB2B" :width="50" :height="50" />
          </span>

          <div class="ml-2">{{ $t('vega.popup.activateForB2BOnly') }}</div>
        </div>

        <div class="flex items-center">
          <span @click="toggleEnableVegaPopup">
            <toggle-switch-icon :enabled="isVegaPopupEnable" :width="50" :height="50" />
          </span>

          <div class="ml-2">Pop-Up</div>
        </div>

        <div class="pap-popup__preview-settings__info">
          <img src="@/assets/images/vega/action-cursor-icon.svg" class="pap-popup__preview-settings__info__action-img" />

          <div class="flex items-center">
            <div class="mr-3 pap-popup__preview-settings__info__title">{{ $t('vega.choose.vegaAnalysesAreActive') }}</div>
            <img src="@/assets/images/vega/blue-check-filled-icon.svg" />
          </div>

          <div class="pap-popup__preview-settings__info__description">{{ $t('vega.choose.vegaActivatePopupText') }}</div>

          <div class="pap-popup__preview-settings__info__list">
            <div class="pap-popup__preview-settings__info__list__item">{{ $t('vega.choose.vegaActivatePopupListItem_1') }}</div>
            <div class="pap-popup__preview-settings__info__list__item">{{ $t('vega.choose.vegaActivatePopupListItem_2') }}</div>
          </div>
        </div>
      </div>

      <div>
        <img src="@/assets/images/vega/vega-analyses-preview.png" />
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import vSelect from 'vue-select'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const duration = require('dayjs/plugin/duration')
dayjs.extend(utc)
dayjs.extend(duration)

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import VegaPreview from '../components/VegaPreview.vue'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'

const VEGA_POPUP_POSITION = Object.freeze({
  CENTERED: { text: 'Centered', value: 'centered' },
  VISITOR_MOUSE_POSITION: { text: 'Visitor Mouse Position', value: 'visitor-mouse-position' },

  CENTER_TOP: { text: 'Center Top', value: 'center-top' },
  CENTER_BOTTOM: { text: 'Center Bottom', value: 'center-bottom' },

  LEFT_TOP: { text: 'Left Top', value: 'left-top' },
  LEFT_CENTER: { text: 'Left Center', value: 'left-center' },
  LEFT_BOTTOM: { text: 'Left Bottom', value: 'left-bottom' },

  RIGHT_TOP: { text: 'Right Top', value: 'right-top' },
  RIGHT_CENTER: { text: 'Right Center', value: 'right-center' },
  RIGHT_BOTTOM: { text: 'Right Bottom', value: 'right-bottom' }
})

export default {
  name: 'VegaStepPopupText',
  components: {
    vSelect,
    quillEditor,
    VegaPreview,
    ToggleSwitchIcon
  },
  data() {
    return {
      vegaPopupName: '',
      VEGA_POPUP_POSITION,
      popupText: '',
      popupMessage: '',
      isActivateOnlyB2B: false,
      isVegaPopupEnable: true,
      showVegaOnlyWhenAgentsOnline: false,
      popupPosition: VEGA_POPUP_POSITION.CENTERED.value,
      vegaLockingTime: 5,
      isVegaPopupWithoutImage: false,
      editorOption: {
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline', { size: ['small', false, 'large', 'huge'] }]]
          }
        }
      },
      selectedLanguage: { code: 'en', lnFullText: 'English', lnText: 'ENG' },
      tWebConnectVegaText: '',
      tWebConnectVegaMessage: '',
      infoImg: require('@/assets/images/elements/info.svg'),
      scoreList: [
        {
          text: 'On Fire (84% - 100% VEGA Score)',
          value: 'onfire',
          score: '0.83',
          isSelected: false
        },
        {
          text: 'Hot (67% - 83% VEGA Score)',
          value: 'hot',
          score: '0.67',
          isSelected: false
        },
        {
          text: 'Warm (50% - 66% VEGA Score)',
          value: 'warm',
          score: '0.5',
          isSelected: false
        },
        {
          text: 'Mild (33% - 49% VEGA Score)',
          value: 'mild',
          score: '0.33',
          isSelected: false
        },
        {
          text: 'Cold (17% - 32% VEGA Score)',
          value: 'cold',
          score: '0.17',
          isSelected: false
        },
        {
          text: 'Frozen (1% - 16% VEGA Score)',
          value: 'frozen',
          score: '0.01',
          isSelected: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      selectedVegaWidgetPopup: 'selectedVegaWidgetPopup',
      selectedVegaWidgetPopupId: 'selectedVegaWidgetPopupId',
      selectedLanguageForVegaPopup: 'vegaPopupTranslation/selectedLanguageForVegaPopup',
      hasVegaPopupTranslationUpdated: 'vegaPopupTranslation/hasVegaPopupTranslationUpdated',
      selectedVegaPopupTranslation: 'vegaPopupTranslation/selectedVegaPopupTranslation',
      hasLoadedVegaPopupTranslation: 'vegaPopupTranslation/hasLoadedVegaPopupTranslation'
    }),

    webconnectSupportedLanguages() {
      const languages = [...this.languages]
      languages.push({ code: 'en', lnFullText: 'English', lnText: 'ENG' })
      return languages
    },
    canUpdateTextFields() {
      if (!this.selectedLanguageForVegaPopup || this.selectedLanguageForVegaPopup.code === 'en') return true

      return false
    }
  },
  created() {
    this.editorOption.placeholder = this.$i18n.t('vega.popup.message')
    this.popupText = this.$i18n.t('webConnect.vegaText', 'en')
    this.popupMessage = this.$i18n.t('webConnect.vegaMessage', 'en')
    this.popupPosition = VEGA_POPUP_POSITION.CENTERED.value
    this.isVegaPopupWithoutImage = false
    this.vegaLockingTime = 5
  },
  mounted() {
    this.getVegaPopupThreshold()

    if (this.selectedVegaWidgetPopup) {
      this.vegaPopupName = this.selectedVegaWidgetPopup.name
      this.popupText = this.selectedVegaWidgetPopup.text || this.$i18n.t('webConnect.vegaText')
      this.popupMessage = this.selectedVegaWidgetPopup.message || this.$i18n.t('webConnect.vegaMessage')
      this.popupPosition = this.selectedVegaWidgetPopup.position || VEGA_POPUP_POSITION.CENTERED.value
      this.isVegaPopupWithoutImage = this.selectedVegaWidgetPopup.isVegaPopupWithoutImage || false
      if (typeof this.selectedVegaWidgetPopup.vegaLockingTime === 'number') {
        this.vegaLockingTime = this.selectedVegaWidgetPopup.vegaLockingTime
      }

      if (this.selectedVegaWidgetPopup.b2bOnly === true) {
        this.isActivateOnlyB2B = true
      }

      if (this.selectedVegaWidgetPopup.isVegaPopupEnable === false) {
        this.isVegaPopupEnable = false
      }
      if (typeof this.selectedVegaWidgetPopup.showVegaOnlyWhenAgentsOnline !== 'undefined') {
        this.showVegaOnlyWhenAgentsOnline = this.selectedVegaWidgetPopup.showVegaOnlyWhenAgentsOnline
      }
    }

    if (
      this.selectedLanguage &&
      this.selectedLanguage.code &&
      this.selectedLanguageForVegaPopup &&
      this.selectedLanguageForVegaPopup.code &&
      this.selectedLanguage.code === this.selectedLanguageForVegaPopup.code
    ) {
      this.getTranslation()
    } else {
      this.selectedLanguage = this.selectedLanguageForVegaPopup
    }
  },
  methods: {
    ...mapMutations({
      setVegaPopupName: 'SET_VEGA_POPUP_NAME',
      setVegaPopupText: 'SET_VEGA_POPUP_TEXT',
      setVegaPopupMessage: 'SET_VEGA_POPUP_MESSAGE',
      setVegaPopupPosition: 'SET_VEGA_POPUP_POSITION',
      setVegaLockingTime: 'SET_VEGA_LOCKING_TIME',
      setVegaPopupThreshold: 'SET_VEGA_POPUP_THRESHOLD',
      setIsVegaPopupWithoutImage: 'SET_IS_VEGA_POPUP_WITHOUT_IMAGE',
      setIsVegaPopupEnable: 'SET_IS_VEGA_POPUP_ENABLE'
    }),
    ...mapActions({
      setHasVegaPopupTranslationUpdated: 'vegaPopupTranslation/setHasVegaPopupTranslationUpdated',
      setVegaPopupTranslation: 'vegaPopupTranslation/setVegaPopupTranslation',
      setLoadedVegaPopupTranslation: 'vegaPopupTranslation/setLoadedVegaPopupTranslation',
      setDefaultVegaPopupTranslation: 'vegaPopupTranslation/setDefaultVegaPopupTranslation',
      setSelectedLanguageForVegaPopup: 'vegaPopupTranslation/setSelectedLanguageForVegaPopup',
      updateVegaPopupTranslation: 'vegaPopupTranslation/updateVegaPopupTranslation'
    }),
    getThresholdsAbove(thresholdValue) {
      // Find the threshold score for the given thresholdValue
      const foundItem = this.scoreList.find((item) => item.value === thresholdValue)

      if (!foundItem) {
        console.log(`Threshold value "${thresholdValue}" not found.`)
        throw new Error(`Threshold value "${thresholdValue}" not found.`)
      }

      const thresholdScore = foundItem.score

      // Filter and map the thresholds with score >= thresholdScore
      return this.scoreList.filter((item) => parseFloat(item.score) >= parseFloat(thresholdScore)).map((item) => item.value)
    },
    getVegaPopupThreshold() {
      let thresholds = []
      if (this.selectedVegaWidgetPopup) {
        thresholds = this.selectedVegaWidgetPopup.vegaPopupThreshold
      }
      if (!thresholds) {
        const threshold = this.scoreList.find((item) => item.value === this.company.vegaPopupThreshold)
        if (!threshold) {
          return
        }
        thresholds = this.getThresholdsAbove(threshold.value)
      }

      this.setVegaPopupThreshold(thresholds)

      const newScoreList = JSON.parse(JSON.stringify(this.scoreList))

      newScoreList.forEach((item) => {
        item.isSelected = thresholds.includes(item.value)
      })

      this.scoreList = newScoreList
    },
    async scoreUpdated(threshold) {
      if (threshold && threshold.value) {
        const newScoreList = JSON.parse(JSON.stringify(this.scoreList))

        // Map through scoreList and update isSelected accordingly
        newScoreList.map((item) => {
          if (item.value === threshold.value) {
            return { ...item, isSelected: !item.isSelected } // Toggles isSelected
          }
          return item
        })

        // Convert scoreList to JSON and back to force reactivity
        this.scoreList = JSON.parse(JSON.stringify(newScoreList))

        // Filter and map to get thresholds that are selected
        const thresholds = this.scoreList.filter((x) => x.isSelected).map((item) => item.value)
        this.setVegaPopupThreshold(thresholds)
      }
    },
    loadTranslation() {
      if (this.selectedLanguageForVegaPopup && this.selectedLanguageForVegaPopup.code !== 'en' && this.selectedVegaPopupTranslation) {
        this.tWebConnectVegaText = this.selectedVegaPopupTranslation.text
        this.tWebConnectVegaMessage = this.selectedVegaPopupTranslation.message
      }
    },
    async getTranslation() {
      this.setSelectedLanguageForVegaPopup(this.selectedLanguage)

      try {
        const languageRef = await this.$db
          .collection('translations')
          .doc(this.activeUserInfo.company)
          .collection('vega-popups')
          .doc(this.selectedVegaWidgetPopupId)
          .collection('languages')
          .doc(this.selectedLanguage.code)
          .get()

        const translation = languageRef.data()

        if (translation) {
          this.setVegaPopupTranslation(translation)
          this.setLoadedVegaPopupTranslation(Math.random().toString(36).substring(2, 15))
          this.setHasVegaPopupTranslationUpdated(false)
        } else {
          this.setDefaultVegaPopupTranslation(this.selectedLanguageForVegaPopup.code)
          this.setLoadedVegaPopupTranslation(Math.random().toString(36).substring(2, 15))
          this.setHasVegaPopupTranslationUpdated(false)
        }
      } catch (error) {
        console.log(error.message)
      }
    },
    onTextChange() {
      this.popupMessage = this.$refs.quillEditorA.quill.root.innerHTML
    },
    onTextChangeTranslation() {
      this.tWebConnectVegaMessage = this.$refs.quillEditorATranslation.quill.root.innerHTML
    },
    async toggleActivateOnlyB2B() {
      const isActivateOnlyB2B = Boolean(!this.isActivateOnlyB2B)
      this.isActivateOnlyB2B = isActivateOnlyB2B

      try {
        await this.$vs.loading()
        await this.$db.collection('vega-popups').doc(this.selectedVegaWidgetPopupId).set({ b2bOnly: isActivateOnlyB2B, updated: new Date() }, { merge: true })
        await this.$vs.loading.close()
      } catch (err) {
        //eslint-disable-next-line
        console.log(err)
      }
    },
    async toggleEnableVegaPopup() {
      const isVegaPopupEnable = Boolean(!this.isVegaPopupEnable)
      this.isVegaPopupEnable = isVegaPopupEnable

      try {
        await this.$vs.loading()
        if (this.isVegaPopupEnable) {
          await this.$db
            .collection('vega-popups')
            .doc(this.selectedVegaWidgetPopupId)
            .set({ isVegaPopupEnable: this.$firebase.firestore.FieldValue.delete(), updated: new Date() }, { merge: true })
        } else {
          await this.$db.collection('vega-popups').doc(this.selectedVegaWidgetPopupId).set({ isVegaPopupEnable, updated: new Date() }, { merge: true })
        }

        await this.$vs.loading.close()
      } catch (err) {
        //eslint-disable-next-line
        console.log(err)
      }
    },
    async toggleShowVegaOnlyWhenAgentsAreOnline() {
      const showVegaOnlyWhenAgentsOnline = Boolean(!this.showVegaOnlyWhenAgentsOnline)
      this.showVegaOnlyWhenAgentsOnline = showVegaOnlyWhenAgentsOnline

      try {
        await this.$vs.loading()
        await this.$db.collection('vega-popups').doc(this.selectedVegaWidgetPopupId).set({ showVegaOnlyWhenAgentsOnline, updated: new Date() }, { merge: true })
        await this.$vs.loading.close()
      } catch (err) {
        //eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {
    async 'selectedLanguage.code'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getTranslation()
      }
    },
    hasLoadedVegaPopupTranslation(newVal) {
      if (newVal) {
        this.loadTranslation()
      }
      this.$nextTick(() => {
        this.errors.clear()
        this.$validator.reset()
      })
    },
    tWebConnectVegaText() {
      if (this.tWebConnectVegaText !== this.selectedVegaPopupTranslation.text) {
        this.setHasVegaPopupTranslationUpdated(true)
        this.updateVegaPopupTranslation({ text: this.tWebConnectVegaText })
      }
    },
    tWebConnectVegaMessage() {
      if (this.tWebConnectVegaMessage !== this.selectedVegaPopupTranslation.message) {
        this.setHasVegaPopupTranslationUpdated(true)
        this.updateVegaPopupTranslation({ message: this.tWebConnectVegaMessage })
      }
    },
    vegaPopupName() {
      this.setVegaPopupName(this.vegaPopupName)
    },
    popupText() {
      this.setVegaPopupText(this.popupText)
    },
    popupMessage() {
      this.setVegaPopupMessage(this.popupMessage)
    },
    popupPosition() {
      this.setVegaPopupPosition(this.popupPosition)
    },
    vegaLockingTime() {
      this.setVegaLockingTime(this.vegaLockingTime)
    },
    isVegaPopupWithoutImage() {
      this.setIsVegaPopupWithoutImage(this.isVegaPopupWithoutImage)
    },
    isVegaPopupEnable() {
      this.setIsVegaPopupEnable(this.isVegaPopupEnable)
    }
  }
}
</script>
<style lang="scss" scoped>
input[aria-invalid='true'] {
  border: 2px solid red; /* Set the border color and width */
}
.pap-popup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  margin-top: 10px;
  width: 88vw;
  // height: 90vh;
  // max-height: 835px;
  @media (min-width: 1200px) {
    width: calc(100vw - 410px) !important;
  }

  &-inputs {
    flex: 4;
    display: flex;
    flex-direction: column;
    margin-right: 100px;

    &--text {
      margin-top: 15px;
      &-input {
        padding: 10px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #d9dbdd;
        border-radius: 8px;
      }
    }

    &--textarea {
      margin-top: 22px;

      .quill-editor {
        height: 150px;

        .ql-toolbar {
          max-height: 42px;
        }

        .ql-container {
          max-height: 108px;
          .ql-editor {
            font-family: 'Lato';
          }
        }
      }
    }
  }

  &-preview {
    flex: 5;
    display: flex;
    flex-direction: column;
  }

  &__preview-settings {
    &__info {
      position: relative;
      margin-right: 30px;
      padding: 20px 0 0 98px;

      &__action-img {
        position: absolute;
        top: 0;
        left: 20px;
      }

      &__title {
        color: rgb(66, 181, 255);
        font-size: 30px;
        font-weight: 700;
      }

      &__description {
        margin-top: 15px;
        max-width: 300px;
        color: rgb(69, 90, 100);
        font-size: 22px;
      }

      &__list {
        margin-top: 20px;

        &__item {
          position: relative;
          display: flex;
          align-items: center;
          color: rgb(69, 90, 100);
          font-weight: 700;

          &::before {
            content: '';
            display: block;
            margin-right: 9px;
            width: 17px;
            height: 17px;
            background-image: url('../../../assets/images/vega/blue-check-mark-icon.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.checkbox-main {
  font-size: 18px;

  .vs-checkbox {
    margin-right: 20px;
    width: 22px;
    height: 22px;
  }

  &--lighten {
    .vs-checkbox {
      background: rgb(149, 196, 214) !important;
      border: 2px solid rgb(149, 196, 214) !important;
      transform: rotate(0deg) !important;

      .vs-checkbox--check {
        transform: translate(0px) !important;
      }

      .vs-checkbox--icon {
        opacity: 1 !important;
        color: rgb(255, 255, 255) !important;
        transform: translate(0px) !important;
      }
    }
  }

  &--active-disabled {
    .vs-checkbox {
      opacity: 1 !important;
      background: rgba(var(--vs-primary), 1) !important;
      border: 2px solid rgba(var(--vs-primary), 1) !important;
    }
  }
}

.language-selection {
  margin-top: 20px;
}

.language-selection-select {
  .vs__dropdown-toggle {
    background-color: rgba(var(--vs-primary), 1) !important;
    cursor: pointer;
  }
  .vs__selected,
  .vs__search,
  .vs__actions {
    color: #fff !important;
  }
  font-size: 16px;
  margin-bottom: 5px;
}

.pap-popup-inputs--text {
  input:disabled {
    background-color: #eee;
  }
}

.pap-popup-inputs--textarea {
  .quill-editor {
    .ql-toolbar {
      max-height: 42px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .ql-container {
      max-height: 108px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      .ql-editor {
        font-family: 'Lato';
      }
    }
    .ql-disabled {
      background-color: #eee;
    }
  }
}
</style>
